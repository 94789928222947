<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <v-avatar color="grey lighten-2" class="mx-2" size="120">
                <v-img :src="photoUrl()"></v-img>
              </v-avatar>
            </v-row>
            <v-row justify="center">
              <h3>
                <p class="text-center">
                  {{ displayName }}
                </p>
              </h3>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-btn outlined color="#00c300" small block @click="linkSocial()">
                  <v-icon left> fab fa-line </v-icon>
                  เชื่อมต่อบัญชี line นี้ กับ BCRM
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "linkFacebookProfileCard",
  components: {},
  props: {},
  data() {
    return {
      id: null,
      displayName: null,
      photo: null,
    };
  },
  mounted() {},
  created() {
    this.id = this.$route.query.id;
    this.displayName = this.$route.query.displayname;
    this.photo = this.$route.query.photo;
    this.$router.replace({ query: null });
  },
  computed: {},
  methods: {
    linkSocial() {
      const params = {
        provider_id: this.id,
        provider_type: 'line'
      }
      axios
        .post("/user/authenticate/social/link", params)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.linkSocialResponse = response.data;
          this.loading = false;
          if (this.linkSocialResponse.status === "success") {
            this.$router.push("/profile")   
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    photoUrl() {
      return this.photo;
    },
  },
};
</script>
